import { useMemo } from "react";
import { IExportConfig } from "..";
import { IUser } from "../../../types/resources";
import { exportKeyWrapper } from "./exportKeyWrapper";
import { formatDateField } from "../../date";
import { translateAccountType, translateFeatures } from "../../table/columns/accountList";

export const useAccountListExportOptions = () => useMemo<IExportConfig<IUser>[]>(() => [
  {
    header: 'Name',
    valueExtractor: exportKeyWrapper('name'),
  },
  {
    header: 'Type',
    valueExtractor: exportKeyWrapper('accountType', undefined, translateAccountType),
  },
  {
    header: 'Enabled',
    valueExtractor: exportKeyWrapper('enabled'),
  },
  {
    header: 'Code',
    valueExtractor: exportKeyWrapper('accountCode'),
  },
  {
    header: 'Active Users',
    valueExtractor: exportKeyWrapper('activeUserCount'),
  },
  {
    header: 'Users Due For Assessment',
    valueExtractor: exportKeyWrapper('usersDueForAssessment'),
  },
  {
    header: 'Users Being Assessed',
    valueExtractor: exportKeyWrapper('usersBeingAssessed'),
  },
  {
    header: 'Users With Overdue Assessment',
    valueExtractor: exportKeyWrapper('usersBeingAssessedOverdue'),
  },
  {
    header: 'Total Administrators',
    valueExtractor: exportKeyWrapper('totalAdministrators'),
  },
  {
    header: 'Last Admin Login',
    valueExtractor: exportKeyWrapper('lastAdminLogin', undefined, formatDateField),
  },
    {
    header: 'Managed',
    valueExtractor: exportKeyWrapper('isManaged', undefined, isManaged => isManaged ? 'Yes' : '-'),
  },
  {
    header: 'Features',
    valueExtractor: exportKeyWrapper('enabledCapabilities', undefined, features => translateFeatures(features, ', ')),
  },
  {
    header: 'Created On',
    valueExtractor: exportKeyWrapper('createdAt', undefined, formatDateField),
  },
], []);

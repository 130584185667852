import { useMemo } from "react";
import { IExportConfig } from "..";
import { IIssue, IIssueRiskId } from "../../../types/resources";

import { getRiskText } from "../../questionResponseIssue/riskCategory";
import { exportKeyWrapper } from "./exportKeyWrapper";
import { getResolvedText, getGuidanceViewedInfo } from "../../questionResponseIssue/issueStatus";
import { getAnswerString, getQuestionText } from "../../questionResponseIssue/questonAnswer";
import { formatDateField } from "../../date";
import { getIssueUserResolvableText } from "../../questionResponseIssue";

export const useIssueListExportOptions = (simple: boolean = false) => useMemo<IExportConfig<IIssue>[]>(() => simple ? [
  {
    header: 'Issue',
    valueExtractor: exportKeyWrapper('issueTemplate.name'),
  },
  {
    header: 'Category',
    valueExtractor: exportKeyWrapper('issueTemplate.issueCategory.name'),
  },
  {
    header: 'Date',
    valueExtractor: exportKeyWrapper('createdAt', undefined, formatDateField),
  },
  {
    header: 'Question',
    valueExtractor: getQuestionText,
  },
  {
    header: 'Answer',
    valueExtractor: exportKeyWrapper('questionResponse.data.value', undefined, getAnswerString),
  },
  {
    header: 'Status',
    valueExtractor: exportKeyWrapper('meta.isOpen', 'unknown', getResolvedText),
  },
  {
    header: 'Risk Level',
    valueExtractor: exportKeyWrapper<IIssue, IIssueRiskId>('meta.riskCategory.data.id', '-', getRiskText),
  },
  {
    header: 'User Resolvable',
    valueExtractor: exportKeyWrapper('meta.userResolvable', undefined, getIssueUserResolvableText),
  },
  {
    header: 'Guidance Given',
    valueExtractor: exportKeyWrapper('automatedIntervention.automatedInterventionTemplate.name'),
  },
  {
    header: 'Guidance Viewed',
    valueExtractor: exportKeyWrapper('automatedIntervention.firstViewedAt', '-', getGuidanceViewedInfo),
  },
] : [
  {
    header: 'First Name',
    valueExtractor: exportKeyWrapper('user.firstName'),
  },
  {
    header: 'Last Name',
    valueExtractor: exportKeyWrapper('user.lastName'),
  },
  {
    header: 'Email Address',
    valueExtractor: exportKeyWrapper('user.emailAddress'),
  },
  {
    header: 'Issue',
    valueExtractor: exportKeyWrapper('issueTemplate.name'),
  },
  {
    header: 'Category',
    valueExtractor: exportKeyWrapper('issueTemplate.issueCategory.name'),
  },
  {
    header: 'Assessment',
    valueExtractor: exportKeyWrapper('questionResponse.surveyResponse.surveyTemplate.name'),
  },
  {
    header: 'Date',
    valueExtractor: exportKeyWrapper('createdAt', undefined, formatDateField),
  },
  {
    header: 'Question',
    valueExtractor: getQuestionText,
  },
  {
    header: 'Answer',
    valueExtractor: exportKeyWrapper('questionResponse.data.value', undefined, getAnswerString),
  },
  {
    header: 'Status',
    valueExtractor: exportKeyWrapper('meta.isOpen', 'unknown', getResolvedText),
  },
  {
    header: 'Risk Level',
    valueExtractor: exportKeyWrapper<IIssue, IIssueRiskId>('meta.riskCategory.data.id', '-', getRiskText),
  },
  {
    header: 'User Resolvable',
    valueExtractor: exportKeyWrapper('meta.userResolvable', undefined, getIssueUserResolvableText),
  },
  {
    header: 'Guidance Given',
    valueExtractor: exportKeyWrapper('automatedIntervention.automatedInterventionTemplate.name'),
  },
  {
    header: 'Guidance Viewed',
    valueExtractor: exportKeyWrapper('automatedIntervention.firstViewedAt', '-', getGuidanceViewedInfo),
  },
], [simple]);
